<template>
	<!-- 巡检员列表 -->
	<div class="land-list-polling el-content">
		<a-tabs default-active-key="active" @change="changeTabs">
		    <a-tab-pane key="polling" tab="巡检员">
				<a-space style="margin-bottom: 12px;">
					<a-input-search placeholder="姓名,电话" style="width: 400px" enter-button @search="getPollingList(1,info.limit)" />
					<com-worker-add :work-type="1" :is-shop="isShop" @success="getPollingList(info.page,info.limit)"></com-worker-add>
				</a-space>
				<a-table :data-source="info.list" :pagination="false" row-key="id" :columns="[
					{dataIndex:'id',title:'id'},
					{dataIndex:'account_id',title:'user_id'},
					{dataIndex:'name',title:'姓名'},
					{dataIndex:'mobile',title:'电话'},
					{dataIndex:'managing_parcels',title:'管理地块'},
					{dataIndex:'managing_area',title:'管理面积(㎡)'},
					{dataIndex:'to_delivery_num',title:'待巡检任务'},
					{dataIndex:'quantityorder',title:'已完成巡检任务'},
					{dataIndex:'status',title:'状态',slots:{customRender:'status'}},
					{dataIndex:'action',title:'操作',slots:{customRender:'action'}},
				]" >
				    <template #status="{record}">
				    	<a-tag :color="record.status == 1 ? '#87d068' :'#f50'">{{ record.status == 1 ?'正常':'禁用'}}</a-tag>
				    </template>
				    <template #action="{record}">
						<a-space>
							<com-worker-add 
								:work-type="1" 
								:work-data="record" 
								:is-shop="isShop" 
								:custom="true" 
								@success="getPollingList(info.page,info.limit)">
								<kd-button type="primary" 
									title="编辑" 
									icon="ri-edit-line" 
									v-has="{action:'wxapp_land_polling_edit',plat:isShop}">
								</kd-button>
							</com-worker-add>
							<kd-button type="danger" icon="ri-delete-bin-5-line" title="删除" @click="delPolling(record.id)" v-has="{action:'wxapp_land_polling_del',plat:isShop}"></kd-button>
							<kd-button type="success" icon="ri-wechat-2-line" title="授权公众号" @click="authPolling(record.id)" v-has="{action:'wxapp_land_polling_auth',plat:isShop}"></kd-button>
						</a-space>
				    </template>
				</a-table>
		    </a-tab-pane>
		    <a-tab-pane key="rule" tab="巡检规则" force-render>
				<com-power-form :form-data="[
					{label:'短信通知工人',type:'radio',value:'',key:'are_workers_informed_by_sms',dataType:'string',
						options:[
							{value:'1',label:'通知'},
							{value:'0',label:'不通知'},
						]
					},
					{label:'微信通知工人',type:'radio',value:'',key:'are_workers_informed_by_wechat',dataType:'string',
						options:[
							{value:'1',label:'通知'},
							{value:'0',label:'不通知'},
						]
					},
					{label:'土地巡检周期',type:'number',value:'',key:'land_inspection_cycle',dataType:'string',addon:'天'},
				]"></com-power-form>
		    </a-tab-pane>
		</a-tabs>
		<a-modal title="巡检员授权公众号" :visible="show.qrcode" @cancel="show.qrcode = false" width="300px" :footer="null">
			<div style="width: 100%;text-align: center;">
				<img style="width: 200px; height: 200px" :src="info.qrcode">
			</div>			
		</a-modal>
	</div>
</template>

<script>
import comSelectUser from '@/components/miniapp/com-select-user.vue'
import comWorkerAdd from '@/components/layout/common/com-worker-add.vue'
import comPowerForm from '@/components/form/com-power-form.vue'
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
export default{
	name:"page-polling",
	components:{
		comSelectUser,
		comWorkerAdd,
		comPowerForm
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{key:'',type:1},
			info:{
				list:[],
				page:1,
				count:10,
				qrcode:"",	//授权公众号二维码
			},
			show:{
				user:false,
				qrcode:false,
			},
		})
		getPollingList(1,_d.info.limit)
		function getPollingList(page,limit){
			landModel.getInspector(page,limit,_d.search,res=> _d.info = {limit,...res})
		}

		function authPolling(id){
			landModel.getInspectorWxQrcode(id,res=>{
				_d.info.qrcode = res
				_d.show.qrcode = true
			})
		}
		const delPolling = (id)=> landModel.deleteInspector(id,()=>getPollingList(_d.info.page,_d.info.limit))

		return{
			...toRefs(_d),
			getPollingList,
			delPolling,
			authPolling
		}
	},
}
</script>
<style lang="scss">
</style>
